import React, { useEffect } from "react";
import { useData } from "../../../util/dataProvider";
import { motion } from "framer-motion";
import Calendar from "../../../assets/icons8-calendar.svg";
import Krawatte from "../../../assets/icons8-shirt.svg";
import { Link } from "react-router-dom";
export default function Bereiche() {
  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const item = {
    hidden: { scale: 0 },
    show: { scale: 1 },
  };
  useEffect(() => {
    setTitle("Tools");
  }, []);

  const { setTitle, formbuilder } = useData();

  return (
    <div id="Dashboard">
      <motion.div
        className="bereiche"
        variants={container}
        initial="hidden"
        animate="show"
      >
        <motion.div
          style={{ gridColumn: "span 3" }}
          transition={{ ease: "easeOut" }}
          key={"tool-1"}
          variants={item}
        >
          <a
            rel="noopener noreferrer"
            href="https://webcom.sobus.net/WebComm/"
            className="bereich"
            target="_blank"
          >
            <div
              className="icon"
              style={{ backgroundColor: "rgb(250, 212, 63)" }}
            >
              <img src={Calendar} alt={"Calendar"} />
            </div>
            <div className="title">Dienstplan</div>
          </a>
        </motion.div>
        <motion.div
          style={{ gridColumn: "span 3" }}
          transition={{ ease: "easeOut" }}
          key={"tool-2"}
          variants={item}
        >
          <a
            rel="noopener noreferrer"
            href="https://logokrawatte.de/sws/de/"
            className="bereich"
            target="_blank"
          >
            <div className="icon" style={{ backgroundColor: "#c1152b" }}>
              <img src={Krawatte} alt={"Krawatte"} />
            </div>
            <div className="title">Dienstkleidung</div>
          </a>
        </motion.div>
        {formbuilder && (
          <motion.div
            style={{ gridColumn: "span 3" }}
            transition={{ ease: "easeOut" }}
            key={"tool-3"}
            variants={item}
          >
            <Link
              rel="noopener noreferrer"
              to="/tool/formbuilder"
              className="bereich"
            >
              <div
                className="icon"
                style={{ backgroundColor: formbuilder.color }}
              >
                <img src={formbuilder.icon} alt={"Formbuilder"} />
              </div>
              <div className="title">{formbuilder.label}</div>
            </Link>
          </motion.div>
        )}
      </motion.div>
    </div>
  );
}
