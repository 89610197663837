import React from 'react';
import { useHistory } from 'react-router-dom';
export default function PDF({ pdf }) {
	const history = useHistory();
	if (!pdf) {
		return '';
	}
	const hideLoader = () => {
		if (window.android) {
			setTimeout(function () {
				window.android.hideLoader();
			}, 300);
		}
	};
	return (
		<div className="pdf">
			<a
				className="btn"
				rel="noopener noreferer"
				onClick={() => hideLoader()}
				href={pdf}
			>
				PDF herunterladen
			</a>
		</div>
	);
}
