import React, { useEffect, useState } from 'react'
import './downloads.scss'
import { useData } from '../../../util/dataProvider'
import image from '../../../assets/sws-image.svg'
import pdf from '../../../assets/sws-pdf.svg'
import excel from '../../../assets/sws-excel.svg'
import file from '../../../assets/sws-file.svg'
import { motion } from 'framer-motion'
import { useHistory } from 'react-router-dom'

export default function Downloads() {
  const history = useHistory()
  const { pagesData, setTitle } = useData()

  const item = {
    hidden: { x: '-150%' },
    show: { x: 1 },
  }

  useEffect(() => {
    setTitle('Downloads')
    const scrollDiv = document.getElementById('Content')
    if (scrollDiv) {
      scrollDiv.scrollTo(0, 0)
    }
  }, [])

  function getDownloadUrl(url) {
    return url
  }

  const downloadsIcon = (data) => {
    switch (data.subtype) {
      case 'jpeg':
        return image
      case 'png':
        return image
      case 'pdf':
        return pdf
      case 'xls':
        return excel
      default:
        return file
    }
  }

  const downloadsDate = (modifiedTime) => {
    let modified = new Date(modifiedTime.replace(' ', 'T'))
    let day = modified.getDate()
    let month = modified.getMonth() + 1
    let year = modified.getFullYear()
    return `${day}.${month}.${year}`
  }
  const hideLoader = () => {
    if (window.android) {
      setTimeout(function () {
        window.android.hideLoader()
      }, 300)
    }
  }
  return (
    <div className="container">
      <motion.div
        className="box-container"
        transition={{ ease: 'easeOut' }}
        variants={item}
        initial="hidden"
        animate="show"
      >
        <div className="downloads-allgemein">Allgemein</div>
        {pagesData &&
          pagesData.downloads.map((data, idx) => {
            return (
              <a
                onClick={() => hideLoader()}
                key={`download-${idx}`}
                href={
                  data.datei && data.datei.url && getDownloadUrl(data.datei.url)
                }
              >
                <div
                  className="box"
                  style={{
                    borderRadius:
                      idx === pagesData.downloads.length - 1
                        ? '0 0 5px 5px'
                        : 'auto',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      className="img"
                      style={{
                        backgroundImage: `url(${
                          data && data.datei && downloadsIcon(data.datei)
                        })`,
                        borderRadius: '0',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                      }}
                    ></div>
                    <div className="info">
                      <span className="name">{data && data.name}</span>
                      <span>
                        Stand{' '}
                        {data.datei &&
                          data.datei.modified &&
                          downloadsDate(data.datei.modified)}{' '}
                        |{' '}
                        {data.datei &&
                          data.datei.filesize &&
                          Math.floor(data.datei.filesize / 1024) + ' kb'}
                      </span>
                    </div>
                  </div>
                </div>
              </a>
            )
          })}
      </motion.div>
    </div>
  )
}
