import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import News from './News'
import Thumbs from '../../../assets/thumbs.svg'

import { useData } from '../../../util/dataProvider'

export default function Nineeuro() {
  const history = useHistory()

  useEffect(() => {
    const scrollDiv = document.getElementById('Content')
    if (scrollDiv && localStorage.getItem('posiY')) {
      window.scrollTo(0, localStorage.getItem('posiY'))
      localStorage.setItem('posiY', 0)
    }
  }, [])
  const { data, userData, setTitle, showNews } = useData()

  const [meldungen, setMeldungen] = useState(showNews && showNews.length)
  const [nineeuroNews, setNineEuroNews] = useState()
  console.log(data)
  useEffect(() => {
    setTitle('<span class="yellow">SWS</span> mobi')
  }, [])

  useEffect(() => {
    generatenineeuroNews()
  }, [data])

  function generatenineeuroNews() {
    let posts = []
    for (let i = 0; i < data.length; i++) {
      data[i].posts.map((post) => {
        if (post.nine_topic) {
          post.extraSlug = data[i].slug
          posts.push(post)
        }
      })
    }
    console.log(posts)
    if (posts.length > 0) {
      setNineEuroNews(posts)
    } else {
      setNineEuroNews(false)
    }
  }
  return (
    <div id="Dashboard">
      <div className="news-title">
        {!nineeuroNews ? (
          <div style={{ fontSize: '1rem', width: '100%', textAlign: 'center' }}>
            Du bist auf dem neuesten Stand!{' '}
          </div>
        ) : null}
      </div>
      <News data={nineeuroNews} nine={true} />
    </div>
  )
}
