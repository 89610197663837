import React, { useEffect } from "react";
import { Error, FormattedDate } from "../../common";
import { useParams } from "react-router-dom";
import { useData } from "../../../util/dataProvider";
import Text from "./Text";
import Video from "./Video";
import Bild from "./Bild";
import PDF from "./PDF";
import user from "../../../assets/sws-user.svg";
import ScrollTop from "../../common/ScrollTop";

import { motion } from "framer-motion";

export default function Meldung() {
  const {
    data,
    showNews,
    setShowNews,
    setTitle,
    updateViewedPosts,
    checkViewedPosts,
    userData,
  } = useData();
  const { id, slug } = useParams();
  useEffect(() => {
    if (showNews) {
      viewedNewsAus();
    }
    setTitle(bereich.kurzname);

    updateViewedPosts(id);
  }, []);

  const scrollDiv = document.getElementById("Content");
  if (scrollDiv) {
    scrollDiv.scrollTo(0, 0);
  }

  const bereich = data.find((bereich) => {
    return bereich.slug === slug;
  });
  const meldung = bereich.posts.find((meldung) => {
    return parseInt(meldung.id) === parseInt(id);
  });
  if (!bereich || !meldung) {
    return <Error message="Diese Meldung konnte nicht gefunden werden." />;
  }
  const { date, additionalContent, content, vorschaubild, author } = meldung;

  const item = {
    hidden: { x: "-150%" },
    show: { x: 1 },
  };
  function viewedNewsAus() {
    if (showNews) {
      let target = showNews.findIndex(function (element) {
        return element.id == id;
      });
      let newArray = [...showNews];
      newArray.splice(target, 1);
      if (newArray.length === 0) {
        setShowNews(false);
      } else {
        setShowNews(newArray);
      }
    }
  }

  return (
    <motion.div
      transition={{ ease: "easeOut" }}
      variants={item}
      initial="hidden"
      animate="show"
      id="Meldung"
    >
      <div className="content">
        <div className="meldung-header">
          <div className="meldung-header-left">
            <div
              className="meldung-icon"
              style={{
                backgroundImage: `url(${meldung.bereich_icon})`,
                backgroundColor: meldung.bereich_farbe,
              }}
            ></div>
            <div className="meldung-bereich">
              <div
                style={{ fontSize: "16px" }}
                dangerouslySetInnerHTML={{
                  __html: meldung.bereich_title,
                }}
              ></div>
              <div>
                {" "}
                <FormattedDate date={date} />
              </div>
            </div>
          </div>
          <div className="meldung-header-right">
            <div
              className={`meldung-viewed ${
                checkViewedPosts(id) ? "" : "viewed"
              }`}
            ></div>
          </div>
        </div>
        {vorschaubild && (
          <div
            className="bild"
            style={{
              backgroundColor: meldung.bereich_farbe,
              backgroundImage: "url(" + vorschaubild + ")",
            }}
          ></div>
        )}
        <div className="inner">
          <div className="meldung-content">
            {content &&
              content.map((zeile) => {
                return zeile.map((spalte, idx) => {
                  if (spalte.typ === "text")
                    return <Text key={idx} text={spalte.text} />;
                  if (spalte.typ === "video")
                    return <Video key={idx} video={spalte.video} />;
                  if (spalte.typ === "bild")
                    return <Bild key={idx} bild={spalte.bild} />;
                  if (spalte.typ === "pdf")
                    return <PDF key={idx} pdf={spalte.pdf} />;
                  return null;
                });
              })}
            {additionalContent && <Text text={additionalContent} />}
          </div>
          {author && (
            <div className="meldung-box">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  className="img"
                  style={{
                    backgroundImage: author.profilepic
                      ? `url(${author.profilepic})`
                      : `url(${user})`,
                  }}
                ></div>
                <div className="info">
                  <span id="ansprechpartner">Ihr Ansprechpartner:</span>
                  <span className="name">{author.name}</span>
                </div>
              </div>
              {!userData.used && (
                <div className="partner-email">
                  <a
                    href={`tel:${
                      author.phone &&
                      author.phone
                        .replace(/\s*/g, "")
                        .replace(/\([^\)]*\)/g, "")
                        .replace(/\/*/g, "")
                        .replace(/\-*/g, "")
                    }`}
                  >
                    <div className="icon-phone"></div>
                  </a>
                  <a href={`mailto:${author.email}`}>
                    <div className="icon-email"></div>
                  </a>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
}
