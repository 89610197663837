import React, { Fragment } from "react";
import { Link } from "react-router-dom";


class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error);
  }
  render() {
    if (this.state.hasError) {
      return (
        <div id="Layout">
          <div id="Background"></div>
          <div id="Header">
          </div>
          <div id="Content" style={{
            position: 'relative', width: '100vw'
          }}>
            <div style={{ margin: '50px auto ', color: 'white', width: 'fit-content' }}>
              <h1>Es gab ein Fehler</h1>
              <Link to='/'>
                <div className='btn' >
                  Zur Hauptmenu
              </div>
              </Link>
            </div>
          </div >
        </div >
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;