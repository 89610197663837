import React, { useEffect } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useData } from '../../../util/dataProvider';
import { Loader } from '../../common';

export default function RedirectLogin() {
	const { doLoginWithToken, authorized } = useData();
	const { token } = useParams();
	useEffect(() => {
		doLoginWithToken(token);
	}, [token]);
	if (authorized) {
		return <Redirect to="/"></Redirect>;
	}
	return (
		<div id="Layout">
			<div id="Background"></div>
			<Loader />
		</div>
	);
}
