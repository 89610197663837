import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import News from './News'
import Thumbs from '../../../assets/thumbs.svg'

import { useData } from '../../../util/dataProvider'

export default function Dashboard() {
  const history = useHistory()

  const { data, userData, setTitle, showNews, setShowNews } = useData()

  const [meldungen, setMeldungen] = useState(showNews && showNews.length)

  useEffect(() => {
    setTitle('<span class="yellow">SWS</span> mobi')
  }, [])

  useEffect(() => {
    const scrollDiv = document.getElementById('Content')
    if (scrollDiv && localStorage.getItem('posiY')) {
      scrollDiv.scrollTo(0, localStorage.getItem('posiY'))
      localStorage.setItem('posiY', 0)
    }
  }, [])

  return (
    <div id="Dashboard">
      <div id="Welcome">Hallo {userData && userData.vorname}!</div>;
      <div className="news-title">
        {showNews == 0 || !showNews ? (
          <div style={{ fontSize: '1rem', width: '100%', textAlign: 'center' }}>
            <img
              style={{ width: '150px', margin: '0 auto' }}
              src={Thumbs}
              alt="Thumbs up"
            />
            <br />
            Du bist auf dem neuesten Stand!{' '}
          </div>
        ) : (
          <div style={{ fontSize: '1rem', marginRight: '20px' }}>
            Hier findest du {meldungen} aktuelle Meldung
            {meldungen > 1 ? 'en' : ''} seit deinem letzten Besuch.
          </div>
        )}
      </div>
      <News data={showNews} />
    </div>
  )
}
