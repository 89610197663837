import React from "react";
import Calendar from "../../assets/calendar.svg";

export default function FormattedDate({ date }) {
  const unixTimestamp = date;
  const milliseconds = unixTimestamp * 1000; // 1575909015000
  const dateObject = new Date(milliseconds);
  const humanDateFormat = dateObject.toLocaleString(); //2019-12-9 10:30:15

  return (
    <div className="date">
      <img src={Calendar} alt="Datum" className="calendar" />
      {humanDateFormat}
    </div>
  );
}
