import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useData } from "../../../util/dataProvider";
import { motion } from "framer-motion";

import { Error } from "../../common/";
import user from "../../../assets/sws-user.svg";

export default function Menu() {
  const { userData, setTitle } = useData();
  const history = useHistory();

  const item = {
    hidden: { x: "-150%" },
    show: { x: 1 },
  };

  useEffect(() => {
    setTitle("Menü");
  }, []);
  return (
    <motion.div
      id="Menu"
      transition={{ ease: "easeOut" }}
      variants={item}
      initial="hidden"
      animate="show"
    >
      <div className="user-container">
        <div className="user-info">
          <div style={{ fontWeight: "bold" }}>
            {userData.vorname}
            {" " + userData.nachname}
          </div>
          <div style={{ fontSize: 12 }}>{userData.email}</div>
        </div>
      </div>
      <div className="menu-item-container">
        <div
          className="menu-item news"
          onClick={() => {
            history.push("/");
          }}
        >
          News
        </div>
        <div
          className="menu-item bereich"
          onClick={() => {
            history.push("/bereich");
          }}
        >
          Bereiche
        </div>
        <div
          className="menu-item tools"
          onClick={() => {
            history.push("/tools");
          }}
        >
          Tools
        </div>
        <div
          className="menu-item downloads"
          onClick={() => {
            history.push("/downloads");
          }}
        >
          Downloads
        </div>
        <div
          className="menu-item kontakte"
          onClick={() => {
            history.push("/kontakte");
          }}
        >
          Kontakte
        </div>
        <div
          className="menu-item einstellung"
          onClick={() => {
            history.push("/einstellungen");
          }}
        >
          Einstellungen
        </div>
      </div>
    </motion.div>
  );
}
