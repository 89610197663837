import React from "react";
import { useData } from "../../../util/dataProvider";
import "./faq.scss";
import { motion } from "framer-motion";

export default function Faq() {
    const { pagesData } = useData();
    const item = {
        hidden: { x: "-150%" },
        show: { x: 1 },
    };

    return (
        <div className='container'>
            <span className='title'>Häufig gestellte Fragen</span>
            <motion.div
                className='faq-box'
                transition={{ ease: "easeOut" }}
                variants={item}
                initial='hidden'
                animate='show'>
                <div dangerouslySetInnerHTML={{ __html: pagesData && pagesData.faq }}></div>
            </motion.div>
        </div>
    );
}
