import React from "react";
import { useData } from "../../../util/dataProvider";
import user from "../../../assets/sws-user.svg";
import { motion } from "framer-motion";

export default function Kontakt({ bereich_id }) {
  const { pagesData, userData } = useData();
  const item = {
    hidden: { x: "-150%" },
    show: { x: 1 },
  };
  console.log(pagesData.ansprechpartner);

  return (
    <div className="container" style={{ paddingTop: 0, marginTop: "20px" }}>
      <motion.div
        className="box-container"
        transition={{ ease: "easeOut" }}
        variants={item}
        initial="hidden"
        animate="show"
      >
        {pagesData.ansprechpartner &&
          pagesData.ansprechpartner.map((row, idx) => {
            if (row.acf_fc_layout === "kategorie") {
              return null;
            } else {
              if (row.user_bereich) {
                let index = row.user_bereich.findIndex(
                  (ele) => ele == bereich_id
                );
                if (index >= 0) {
                  return (
                    <div
                      key={`ansprechpartner-${idx}`}
                      className="box"
                      style={{
                        borderRadius:
                          idx === pagesData.ansprechpartner.length - 1
                            ? "0 0 5px 5px"
                            : "auto",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div
                          className="img"
                          style={{
                            backgroundImage: row.profilepic
                              ? `url(${row.profilepic})`
                              : `url(${user})`,
                          }}
                        ></div>
                        <div className="info">
                          <span className="name">{row.name}</span>
                          <span>{row.title}</span>
                        </div>
                      </div>
                      {!userData.used && (
                        <div className="partner-email">
                          <a
                            href={`tel:${
                              row.phone &&
                              row.phone
                                .replace(/\s*/g, "")
                                .replace(/\([^\)]*\)/g, "")
                                .replace(/\/*/g, "")
                                .replace(/\-*/g, "")
                            }`}
                          >
                            <div className="icon-phone"></div>
                          </a>
                          <a href={`mailto:${row.email}`}>
                            <div className="icon-email"></div>
                          </a>
                        </div>
                      )}
                    </div>
                  );
                }
              }
            }
          })}
      </motion.div>
    </div>
  );
}
