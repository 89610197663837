import React, { useState, useEffect } from "react";
import { useData } from "../../../util/dataProvider";
import zxcvbn from "zxcvbn";
import { motion, AnimatePresence } from "framer-motion";
import { Redirect, useHistory } from "react-router-dom";

export default function Password() {
    const history = useHistory();
    const { resetPassword, error } = useData();
    const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]/;

    const [disabled, disableButton] = useState(true); //control button diaabled or not
    const [password, setPassword] = useState(""); //store password
    const [comfirmpassword, setcomfirmPassword] = useState(""); // store password
    const [passwordError, setPasswordError] = useState(""); //errormessage for invaild password
    const [redirect, setRedirect] = useState(false);

    const submitForm = (e) => {
        e.preventDefault();
        resetPassword(password);
        setPassword("");
        setcomfirmPassword("");
        setRedirect(true);
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        disableButton(true);
        checkPassword();
    }, [comfirmpassword, password]);

    const checkPassword = () => {
        switch (true) {
            case password === "":
                disableButton(true);
                setPasswordError("");
                break;
            case password.length < 6:
                disableButton(true);
                setPasswordError(
                    "Das Passwort muss mindestens 6 Zeichen enthalten."
                );
                break;
            case zxcvbn(password).score < 1:
                disableButton(true);
                setPasswordError("Das Password ist zu schwach.");
                break;
            case !regex.test(password):
                disableButton(true);
                setPasswordError(
                    "Das Passwort muss mindestens eine Zahl und einen Buchstaben enthalten."
                );
                break;
            case password !== comfirmpassword:
                disableButton(true);
                setPasswordError("Die Passwörter stimmen nicht üerein.");
                break;
            case password === comfirmpassword:
                disableButton(false);
                setPasswordError("");
                break;
        }
    };

    const itemIn = {
        hidden: { x: "150%" },
        show: { x: 0 },
    };
    if (redirect) history.push("/");

    return (
        <div id='Settings'>
            {redirect ? (
                <Redirect to='/einstellungen' />
            ) : (
                <AnimatePresence>
                    <motion.div
                        initial='hidden'
                        animate='show'
                        exit={{ x: 400 }}
                        transition={{ ease: "easeOut" }}
                        variants={itemIn}>
                        <div id='setPassword'>
                            <div id='Form'>
                                <form onSubmit={submitForm}>
                                    <label>Neues Passwort</label>
                                    <span className='icon-password'></span>
                                    <input
                                        onChange={(e) =>
                                            setPassword(e.target.value)
                                        }
                                        type='password'
                                        name='password'
                                        autoComplete='password'
                                        value={password}
                                    />
                                    {disabled ? (
                                        <div className='hint'>
                                            {passwordError}
                                        </div>
                                    ) : null}
                                    <label>Passwort bestätigen</label>
                                    <input
                                        onChange={(e) =>
                                            setcomfirmPassword(e.target.value)
                                        }
                                        type='password'
                                        name='password'
                                        autoComplete='password'
                                        value={comfirmpassword}
                                    />
                                    {error && (
                                        <div className='error'>{error}</div>
                                    )}
                                    <input
                                        type='submit'
                                        value='Speichern'
                                        disabled={disabled}
                                    />
                                </form>
                            </div>
                        </div>
                    </motion.div>
                </AnimatePresence>
            )}
        </div>
    );
}
