import React from "react";
import { Link } from "react-router-dom";
import { useData } from "../../../util/dataProvider";

export default function Bereich(props) {
    const { getNewCount } = useData();

    const { title, farbe, icon, slug, kurzname } = props.data;
    const number = getNewCount(slug);
    return (
        <Link to={"/bereich/" + slug} className='bereich'>
            <div className='icon' style={{ backgroundColor: farbe }}>
                {icon && <img src={icon} alt={title} />}
                {number > 0 ? <div className='number'>{number}</div> : ""}
            </div>
            <div className='title'>{kurzname}</div>
        </Link>
    );
}
