import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // Th
export default function Bild({ bild }) {
	const [isOpen, setIsOpen] = useState(false);
	return (
		<div className="bild">
			<img src={bild} alt="Bild" onClick={() => setIsOpen(true)} />
			{isOpen && (
				<Lightbox mainSrc={bild} onCloseRequest={() => setIsOpen(false)} />
			)}
		</div>
	);
}
