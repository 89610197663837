import React, { useState } from "react";
import { useData } from "../../../util/dataProvider";
import "./Lunchpaket.scss";
import { Formik, Field, Form, isInteger } from "formik";
import Mahlzeit from "./Mahlzeit";
import axios from "axios";
import config from "../../../util/config";
import SendImg from "../../../assets/send.svg";
import SuccessImg from "../../../assets/success.svg";
import { motion } from "framer-motion";

export default function Lunchpaket() {
    const { lunchpaket, userData, token } = useData();
    const [isSending, setIsSending] = useState(false);
    const [success, setSuccess] = useState(false);

    const submitData = (values) => {
        var day = new Date(values.Datum);
        if (day.getDay() === 5) {
            alert("Für Freitag sind keine Bestellungen möglich.");
            return false;
        }
        setIsSending(true);
        const token = localStorage.getItem("token");
        localStorage.setItem("lunch_tel", values.Telefon);
        values.token = token;
        values.gesamtsumme = getTotal(values);
        axios
            .post(config.url + "/lunchpaket", values)
            .then((response) => {
                setSuccess(true);
                setTimeout(function () {
                    setIsSending(false);
                }, 2000);
            })
            .catch((error) => {
                setIsSending(false);

                alert("Es gab einen Fehler bei der Übertragung.");
            });
    };
    if (!lunchpaket) {
        return "Derzeit kein Formular verfügbar.";
    }
    const { email } = userData;
    if (email === "GWP2@web.de") {
        return (
            <div
                id='settings'
                className='container'
                style={{ textAlign: "center", color: "#FFF" }}>
                Das Lunchpaket ist mit diesem Account nicht verfügbar.
            </div>
        );
    }
    const item = {
        hidden: { x: "-150%" },
        show: { x: 1 },
    };
    const MapItems = () => {
        let i = -1;
        return (
            lunchpaket.mahlzeiten &&
            lunchpaket.mahlzeiten.map(function (mahlzeit, idx) {
                switch (mahlzeit.acf_fc_layout) {
                    case "mahlzeit":
                        i++;
                        return (
                            <Mahlzeit
                                data={mahlzeit}
                                key={`item-${idx}`}
                                alternativeIndex={i}
                            />
                        );
                        break;
                    case "headline":
                        return <h3 key={`item-${idx}`}>{mahlzeit.text}</h3>;
                    default:
                        return "";
                        break;
                }
            })
        );
    };

    const MapValues = () => {
        const data =
            lunchpaket.mahlzeiten &&
            lunchpaket.mahlzeiten.map(function (mahlzeit, idx) {
                if (mahlzeit.titel) {
                    return {
                        name: mahlzeit.titel,
                        checked: false,
                        zusatzoptionen: [],
                        preis: mahlzeit.preis,
                    };
                } else {
                    return false;
                }
            });
        if (data) {
            return data.filter((item) => item);
        } else {
            return [];
        }
    };
    const getTotal = (values) => {
        const totalPrice = values.Bestellung.reduce((total, value) => {
            if (value.preis && value.checked) {
                return total + parseFloat(value.preis);
            }
            return total;
        }, 0);
        const formatedPrice = parseFloat(totalPrice).toLocaleString("de-DE", {
            style: "currency",
            currency: "EUR",
        });
        return formatedPrice;
    };
    function getDate() {
        var d = new Date(),
            month = "" + (d.getMonth() + 1),
            day = "" + (d.getDate() + 1),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        return [year, month, day].join("-");
    }

    const defaultValues = {
        Name: userData.vorname + " " + userData.nachname,
        Dienstnummer: userData.dn,
        Abteilung: "Fahrdienst",
        Telefon: localStorage.getItem("lunch_tel"),
        Abholstelle: "Kantine Weidenstraße",
        Bestellung: MapValues(),
        Sonstiges: "",
        Datum: getDate(),
    };
    if (success) {
        return (
            <motion.div
                transition={{ ease: "easeOut" }}
                variants={item}
                initial='hidden'
                animate='show'
                className='content lunchpaket success'>
                <img src={SuccessImg} alt='Erfolgreich' />
                <p>Ihre Lunchpaket-Bestellung wurde erfolgreich verschickt.</p>
                <p>
                    Sie haben eine Bestätigung an Ihre hinterlegte E-Mail
                    erhalten.
                </p>
                <p>Guten Appetit!</p>
            </motion.div>
        );
    }
    return (
        <React.Fragment>
            {isSending && (
                <div className='sending'>
                    <img src={SendImg} alt='Sendet' />
                </div>
            )}
            <motion.div
                transition={{ ease: "easeOut" }}
                variants={item}
                initial='hidden'
                animate='show'
                className='content lunchpaket'>
                <Formik
                    initialValues={defaultValues}
                    onSubmit={(values, actions) => {
                        submitData(values);
                    }}>
                    {(props) => (
                        <Form>
                            <h1
                                style={{
                                    color: "#FFF",
                                    textAlign: "center",
                                    marginTop: 0,
                                }}>
                                Lunchpaket-Bestellung
                            </h1>
                            <div style={{ color: "#FFF", textAlign: "center" }}>
                                <p>
                                    Bestellungen für Dienstag – Donnerstag:
                                    <br />
                                    <strong>bis 13 Uhr</strong> (am Vortag)
                                </p>
                                <p>
                                    Bestellungen für Montag:
                                    <br />
                                    bis <strong>9.45 Uhr</strong> (Freitag)
                                </p>
                                <p>
                                    Bestellungen für Freitag:
                                    <br />
                                    <strong>Nicht möglich</strong>
                                </p>
                            </div>
                            <h2>Ihre Daten</h2>
                            <div className='field-group'>
                                <label htmlFor='Name'>Name</label>
                                <Field type='text' id='Name' name='Name' />
                                <Field
                                    type='hidden'
                                    id='Dienstnummer'
                                    name='Dienstnummer'
                                />
                            </div>
                            <div className='field-group'>
                                <label htmlFor='Abteilung'>Abteilung</label>
                                <Field as='select' name='Abteilung'>
                                    <option value='Fahrdienst'>
                                        Fahrdienst
                                    </option>
                                    <option value='Werkstatt'>Werkstatt</option>
                                    <option value='Innendienst'>
                                        Innendienst
                                    </option>
                                </Field>
                            </div>
                            <div className='field-group'>
                                <label htmlFor='Telefon'>Telefon</label>
                                <Field id='Telefon' name='Telefon' type='tel' />
                            </div>
                            <div className='field-group'>
                                <label htmlFor='Abholstelle'>Abholstelle</label>
                                <Field as='select' name='Abholstelle'>
                                    <option value='Kantine Weidenstraße'>
                                        Kantine Weidenstraße
                                    </option>
                                </Field>
                            </div>
                            <div className='field-group'>
                                <label htmlFor='Datum'>Bestellung für</label>
                                <Field
                                    lang='de'
                                    id='Datum'
                                    name='Datum'
                                    type='date'
                                />
                            </div>
                            <div style={{ height: "2rem" }}></div>

                            <MapItems />
                            <h3>Sonstiges / Allergien</h3>
                            <div className='field-group'>
                                <Field
                                    id='Sonstiges'
                                    name='Sonstiges'
                                    component='textarea'
                                />
                            </div>
                            <div className='summary'>
                                Gesamtsumme der Bestellung:{" "}
                                <span className='total'>
                                    {getTotal(props.values)}
                                </span>
                            </div>
                            <button type='submit'>Jetzt bestellen</button>
                        </Form>
                    )}
                </Formik>
            </motion.div>
        </React.Fragment>
    );
}
