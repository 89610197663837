import React, { useState } from "react";
import icon from '../../../assets/sws-icon.svg'
import { useData } from "../../../util/dataProvider";


export default function Login() {
    const { doLogin, error } = useData();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();

    const submitForm = (e) => {
        e.preventDefault();
        doLogin(email, password);
    };

    return (
        <div id='Layout'>
            <div id='Login'>
                <div id='Background'></div>
                <div id='Form'>
                    <img src={icon} alt='S' />
                    <form onSubmit={submitForm}>
                        <label htmlFor='email'>E-Mail </label>
                        <span
                            className='icon-user'
                            style={{
                                animation: error ? "shake 0.3s" : null,
                            }}></span>
                        <input
                            onChange={(e) => setEmail(e.target.value)}
                            type='email'
                            name='email'
                            id='email'
                            autoComplete='email'
                        />
                        <label htmlFor='password'>Password</label>
                        <span
                            className='icon-password'
                            style={{
                                animation: error ? "shake 0.3s" : null,
                            }}></span>
                        <input
                            onChange={(e) => setPassword(e.target.value)}
                            type='password'
                            name='password'
                            id='password'
                            autoComplete='password'
                            className='password'
                        />
                        {error && <div className='error'>{error}</div>}
                        <input type='submit' value='Einloggen' />
                    </form>
                </div>
            </div>
        </div>
    );
}
