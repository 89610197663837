import React from 'react';
import { useData } from '../../../util/dataProvider';

export default function Privacy() {
	const { privacyText, hidePrivacy } = useData();

	return (
		<div id="Privacy">
			<div
				className="content"
				dangerouslySetInnerHTML={{ __html: privacyText }}
			></div>
			<div onClick={() => hidePrivacy(true)} className="button">
				Ich habe die
				<br />
				Datenschutzerklärung gelesen
			</div>
		</div>
	);
}
