import React from 'react';
import Meldung from './Meldung';
import { motion } from 'framer-motion';

export default function Meldungen(props) {
	const { data, farbe } = props;
	const container = {
		show: {
			transition: {
				staggerChildren: 0.1,
				delayChildren: 0.2,
			},
		},
	};

	const item = {
		hidden: { x: '-150%' },
		show: { x: 0 },
	};
	return (
		<motion.div
			variants={container}
			initial="hidden"
			animate="show"
			className="meldungen"
		>
			{data &&
				data.map((meldung) => {
					return (
						<motion.div
							transition={{ ease: 'easeOut' }}
							key={meldung.id}
							variants={item}
						>
							<Meldung farbe={farbe} data={meldung} />
						</motion.div>
					);
				})}
		</motion.div>
	);
}
