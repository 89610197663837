import React, { useState } from "react";
import "../Lunchpaket/Lunchpaket.scss";
import SuccessImg from "../../../assets/success.svg";
import { motion } from "framer-motion";

export default function Finish() {
  const item = {
    hidden: { x: "-150%" },
    show: { x: 1 },
  };

  return (
    <motion.div
      transition={{ ease: "easeOut" }}
      variants={item}
      initial="hidden"
      animate="show"
      className="content lunchpaket success"
    >
      <img src={SuccessImg} alt="Erfolgreich" />
      <p>Ihre Meldung wurde erfolgreich verschickt.</p>
    </motion.div>
  );
}
