import React, { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import { useData } from '../../../util/dataProvider'
import Meldungen from './Meldungen'
import Header from './Header'
import { Error } from '../../common/'
import Downloads from './Downloads'
import Kontakte from './Kontakte'
import Wissen from './Wissen'
import { motion, AnimatePresence } from 'framer-motion'

export default function Bereich() {
  const { slug } = useParams()
  const { data, fetchData, setTitle } = useData()
  const [section, setSection] = useState('aktuell')

  const bereich = data.find((bereich) => {
    return bereich.slug === slug
  })

  useEffect(() => {
    setTitle(bereich.kurzname)
    const scrollDiv = document.getElementById('Content')
    if (scrollDiv && localStorage.getItem('posiY')) {
      scrollDiv.scrollTo(0, localStorage.getItem('posiY'))
      localStorage.setItem('posiY', 0)
    }
  }, [])

  if (!bereich)
    return <Error message={'Dieser Bereich konnte nicht gefunden werden'} />
  var timestamp = Math.round(new Date().getTime() / 1000)
  localStorage.setItem('last_' + slug, timestamp)
  const sectionSelect = () => {
    switch (section) {
      case 'aktuell':
        return <Meldungen farbe={bereich.farbe} data={bereich.posts} />
        break
      case 'wissen':
        return <Wissen farbe={bereich.farbe} data={bereich.posts} />
        break
      case 'downloads':
        return <Downloads id={bereich.id} />
        break
      case 'kontakte':
        return <Kontakte bereich_id={bereich.id} />
        break
    }
  }
  return (
    <div id="Bereich">
      <AnimatePresence>
        {section === 'aktuell' && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Header data={bereich} />{' '}
          </motion.div>
        )}

        <div className="headerbar">
          <div
            className={`headerbar-item ${
              section === 'aktuell' ? 'aktive' : ''
            }`}
            onClick={() => {
              setSection('aktuell')
            }}
          >
            Aktuell
          </div>
          <div
            className={`headerbar-item ${section === 'wissen' ? 'aktive' : ''}`}
            onClick={() => {
              setSection('wissen')
            }}
          >
            Wissen
          </div>
          <div
            className={`headerbar-item ${
              section === 'downloads' ? 'aktive' : ''
            }`}
            onClick={() => {
              setSection('downloads')
            }}
          >
            Downloads
          </div>
          <div
            className={`headerbar-item ${
              section === 'kontakte' ? 'aktive' : ''
            }`}
            onClick={() => {
              setSection('kontakte')
            }}
          >
            Kontakte
          </div>
        </div>

        {sectionSelect()}
      </AnimatePresence>
    </div>
  )
}
