

const dev = {
    url: "http://swstest.local/wp-json/swscms/v1",
    client: 16,
};

const production = {
    url: "https://www.sws-cms.de/wp-json/swscms/v1",
    client: 7,
};


const config = process.env.NODE_ENV === "production" ? production : production;
export default config;
