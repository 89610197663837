import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { useData } from "../../../util/dataProvider";
import Bereich from "./Bereich";
import { motion } from "framer-motion";
import Logo from "../../../assets/eezy.svg";
export default function Bereiche() {
  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const item = {
    hidden: { scale: 0 },
    show: { scale: 1 },
  };

  const { data } = useData();

  return (
    <Fragment>
      <motion.div
        className="bereiche"
        variants={container}
        initial="hidden"
        animate="show"
      >
        {Array.isArray(data) &&
          data.map((bereich, idx) => {
            if (bereich.slug !== "aktuelles")
              return (
                <motion.div
                  style={{
                    gridColumn: "span 3",
                  }}
                  transition={{ ease: "easeOut" }}
                  key={bereich.slug}
                  variants={item}
                >
                  <Bereich data={bereich} />
                </motion.div>
              );
            return "";
          })}
      </motion.div>
    </Fragment>
  );
}
