import React, { useEffect } from "react";
import { useData } from "../../../util/dataProvider";
import { motion } from "framer-motion";
import { Link, useHistory } from "react-router-dom";

export default function Settings() {
  const {
    doLogout,
    passwordSuccess,
    setPasswordSuccess,
    showPrivacy,
    userData,
  } = useData();
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
    return function cleanup() {
      setPasswordSuccess(false);
    };
  }, []); //clean the effect, changepassword set to false
  function resetNews() {
    localStorage.setItem("loginTime", 10);
    history.push("/");
    window.location.reload();
  }
  let ani_x; //reverse animation direction
  if (history.location.query) {
    ani_x = "150%";
  } else {
    ani_x = "-150%";
  }
  const { used } = userData;

  if (used) {
    return (
      <div
        id="settings"
        className="container"
        style={{ textAlign: "center", color: "#FFF" }}
      >
        Die Einstellungen sind nicht verfügbar.
      </div>
    );
  }

  return (
    <div id="Settings">
      <motion.div
        initial={{ x: ani_x }}
        animate={{ x: 0 }}
        transition={{ ease: "easeOut" }}
      >
        <Link to={"/password"} style={{ textDecoration: "none" }}>
          <div className="btn">Passwort ändern</div>
        </Link>
        <div className="btn" onClick={showPrivacy}>
          Datenschutzerklärung öffnen
        </div>
        {passwordSuccess ? (
          <div className="successHint">Passwort erfolgreich geändert.</div>
        ) : null}
        <div onClick={doLogout} className="btn abmelden">
          Abmelden
        </div>
        <div
          style={{ marginTop: "20rem", color: "#FFF", opacity: 0.5 }}
          onClick={resetNews}
        >
          Newsfeed zurücksetzen
        </div>
      </motion.div>
    </div>
  );
}
