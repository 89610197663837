import React from "react";
import { NavLink } from "react-router-dom";

export default function Footer() {
  return (
    <div
      id="footer"
      onClick={() => {
        localStorage.setItem("posiY", 0);
      }}
    >
      <NavLink className="footer-item news" exact={true} to="/"></NavLink>
      <NavLink className="footer-item bereich" to="/bereich"></NavLink>
      <NavLink className="footer-item tools" to="/tools"></NavLink>
      <NavLink className="footer-item nine" to="/nineeurobereich">
        <div className="nineeuro">D-TICKET</div>
      </NavLink>
      <NavLink className="footer-item downloads" to="/downloads"></NavLink>

      <NavLink className="footer-item menu" to="/menu"></NavLink>
    </div>
  );
}
