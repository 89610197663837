import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import config from "./config";
import { useHistory } from "react-router-dom";

export const DataContext = React.createContext();
export const useData = () => useContext(DataContext);
export const DataProvider = ({ children, ...initOptions }) => {
  const [authorized, setAuthorized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [privacyText, setPrivacyText] = useState("");
  const [lunchpaket, setLunchpaket] = useState({});
  const [showNews, setShowNews] = useState(null);
  const [hasReadPrivacy, setHasReadPrivacy] = useState(false);
  const [ticker, setTicker] = useState();
  const [userData, setUserData] = useState({});
  const [error, setEerror] = useState(null);
  const [pagesData, setPagesData] = useState({});
  const [title, setTitle] = useState('<span class="yellow">SWS</span> mobi');
  const [passwordSuccess, setPasswordSuccess] = useState(false);
  const [searchResult, setSearchResult] = useState(false);
  const [formbuilder, setFormbuilder] = useState(null);

  useEffect(() => {
    const localToken = localStorage.getItem("token");
    if (localToken && localToken !== "") {
      fetchData(localToken);
    }
    const privacy = localStorage.getItem("hasReadPrivacy");
    if (privacy === "1") {
      setHasReadPrivacy(true);
    }
  }, []);

  useEffect(() => {
    if (data && data.length != 0) {
      setShowNews(checkNews(data));
    }
  }, [data]);

  const doLogin = (email, password) => {
    setEerror(null);
    axios
      .post(config.url + "/login", {
        email: email,
        password: password,
      })
      .then((response) => {
        localStorage.setItem("token", response.data.jwt);

        fetchData(response.data.jwt);
      })
      .catch((error) => {
        if (error.response) {
          setEerror(error.response.data.data.message);
        }
      });
  };

  const resetPassword = (password) => {
    setEerror(null);
    axios
      .post(config.url + "/settings", {
        token: localStorage.getItem("token"),
        password: password,
      })
      .then((response) => {
        localStorage.setItem("token", response.data.jwt);
        fetchData(response.data.jwt);
        setPasswordSuccess(true);
      })
      .catch((error) => {
        if (error.response) {
          setEerror(error.response.data.data.message);
        }
      });
  };

  const doLoginWithToken = (token) => {
    setEerror(null);
    axios
      .post(config.url + "/redirect", {
        token: token,
      })
      .then((response) => {
        localStorage.setItem("token", response.data.jwt);
        localStorage.setItem("loginTime", Date.parse(new Date()) / 1000);
        fetchData(response.data.jwt);
      })
      .catch((error) => {
        window.location.href = "/";
      });
  };

  const doLogout = () => {
    localStorage.removeItem("token");
    setAuthorized(false);
    window.location.href = "/";
  };

  const fetchData = (token) => {
    setLoading(true);
    axios
      .post(config.url + "/data", {
        client: config.client,
        token: token,
      })
      .then((response) => {
        if (response.data.success) {
          console.log("Check", response.data.data.formBuilder);
          setData(response.data.data.bereiche);
          setUserData(response.data.data.user);
          setPrivacyText(response.data.data.privacy);
          setLunchpaket(response.data.data.lunchpaket);
          setPagesData(response.data.data.pages);
          setTicker(response.data.data.ticker);
          setFormbuilder(response.data.data.formbuilder);
          setAuthorized(true);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        if (error.response) localStorage.removeItem("token");
        setAuthorized(false);
        setLoading(false);
      });
  };

  function checkNews(data) {
    let posts = [];
    let current = localStorage.getItem("loginTime");
    if (!current) {
      setShowNews(false);
      let a = Date.parse(new Date()) / 1000;
      localStorage.setItem("loginTime", a);
      return;
    }
    for (let i = 0; i < data.length; i++) {
      data[i].posts.map((post) => {
        if (post.date >= current) {
          post.extraSlug = data[i].slug;
          if (!checkViewedPosts(post.id)) {
            posts.push(post);
          }
        }
      });
    }

    if (posts.length > 0) {
      posts.sort(function (a, b) {
        return b.date - a.date;
      });
      return posts;
    } else {
      setShowNews(false);
    }
  }

  const getNewCount = (slug) => {
    const bereich = data.find((bereich) => {
      return bereich.slug === slug;
    });
    if (!localStorage.getItem("last_" + slug)) return bereich.posts.length;
    const filterDate = bereich.posts.filter((post) => {
      return post.date > localStorage.getItem("last_" + slug);
    });
    return filterDate.length;
  };

  const hidePrivacy = () => {
    setHasReadPrivacy(true);
    localStorage.setItem("hasReadPrivacy", 1);
  };
  const showPrivacy = () => {
    setHasReadPrivacy(false);
    localStorage.removeItem("hasReadPrivacy");
  };

  const updateViewedPosts = (id) => {
    let array = JSON.parse(localStorage.getItem("viewedPosts"));
    if (array && array.length > 0) {
      let idx = array.findIndex((ele) => parseInt(ele) == id);
      if (idx < 0) {
        array.push(id);
        localStorage.setItem("viewedPosts", JSON.stringify(array));
      }
    } else {
      let array = [];
      array.push(id);
      localStorage.setItem("viewedPosts", JSON.stringify(array));
    }
  };

  const checkViewedPosts = (id) => {
    let array = JSON.parse(localStorage.getItem("viewedPosts"));
    if (array && array.length > 0) {
      let idx = array.find((ele) => parseInt(ele) == id);
      if (idx) {
        return true;
      } else {
        return false;
      }
    }
  };

  const exports = {
    lunchpaket,
    fetchData,
    loading,
    authorized,
    data,
    title,
    setTitle,
    getNewCount,
    userData,
    privacyText,
    hasReadPrivacy,
    hidePrivacy,
    showPrivacy,
    ticker,
    doLogin,
    doLogout,
    doLoginWithToken,
    error,
    resetPassword,
    passwordSuccess,
    setPasswordSuccess,
    pagesData,
    showNews,
    setShowNews,
    updateViewedPosts,
    checkViewedPosts,
    searchResult,
    setSearchResult,
    formbuilder,
  };
  return (
    <DataContext.Provider value={exports}>{children}</DataContext.Provider>
  );
};
