import React, { useState } from 'react'
import './formbuilder.scss'
import Resizer from 'react-image-file-resizer'

export default function Upload({ file, setFile, id }) {
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0]
      showResult(img)
    }
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        500,
        500,
        'JPEG',
        100,
        0,
        (uri) => {
          resolve(uri)
        },
        'base64',
      )
    })

  function showResult(sfile) {
    resizeFile(sfile)
      .then((data) => {
        let newFile = [...file]
        newFile[id] = data
        setFile([...newFile])
      })
      .catch((err) => console.log(err))
  }

  return (
    <div>
      <input
        type="file"
        name="myImage"
        accept="image/*"
        onChange={(e) => onImageChange(e)}
      />
    </div>
  )
}
