import React, { useEffect } from "react";
import "./kontakt.scss";
import { useData } from "../../../util/dataProvider";
import user from "../../../assets/sws-user.svg";
import { motion } from "framer-motion";

export default function Kontakt() {
  const { pagesData, setTitle, userData } = useData();
  const item = {
    hidden: { x: "-150%" },
    show: { x: 1 },
  };

  useEffect(() => {
    setTitle("Kontakte");
  }, []);

  return (
    <div className="container">
      <motion.div
        className="box-container"
        transition={{ ease: "easeOut" }}
        variants={item}
        initial="hidden"
        animate="show"
      >
        {pagesData &&
          pagesData.ansprechpartner.map((row, idx) => {
            if (row.acf_fc_layout === "kategorie") {
              return (
                <div
                  style={{
                    backgroundColor: row.farbe,
                    borderRadius: idx === 0 ? "5px 5px 0 0" : "auto",
                  }}
                  key={`ansprechpartner-${idx}`}
                  className="kontakt-presse"
                >
                  {row.name}
                </div>
              );
            } else {
              return (
                <div
                  key={`ansprechpartner-${idx}`}
                  className="box"
                  style={{
                    borderRadius:
                      idx === pagesData.ansprechpartner.length - 1
                        ? "0 0 5px 5px"
                        : "auto",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="img"
                      style={{
                        backgroundImage: row.profilepic
                          ? `url(${row.profilepic})`
                          : `url(${user})`,
                      }}
                    ></div>
                    <div className="info">
                      <span className="name">{row.name}</span>
                      <span>{row.title}</span>
                    </div>
                  </div>
                  {!userData.used && (
                    <div className="partner-email">
                      <a
                        href={`tel:${
                          row.phone &&
                          row.phone
                            .replace(/\s*/g, "")
                            .replace(/\([^\)]*\)/g, "")
                            .replace(/\/*/g, "")
                            .replace(/\-*/g, "")
                        }`}
                      >
                        <div className="icon-phone"></div>
                      </a>
                      <a href={`mailto:${row.email}`}>
                        <div className="icon-email"></div>
                      </a>
                    </div>
                  )}
                </div>
              );
            }
          })}
      </motion.div>
    </div>
  );
}
