import React, { useState } from 'react';
import { Field, useField } from 'formik';

export default function Mahlzeit({ data, alternativeIndex }) {
	const [nameValues, test, nameHelper] = useField(
		`Bestellung[${alternativeIndex}]`
	);
	const [checkValues, sss, checkHelper] = useField(
		`Bestellung[${alternativeIndex}].checked`
	);

	const [optionValues, ss2, optionHelper] = useField(
		`Bestellung[${alternativeIndex}].zusatzoptionen`
	);

	function addRemove(label) {
		if (data.zusatzoption_single) {
			if (optionValues.value === label) {
				optionHelper.setValue('');
			} else {
				optionHelper.setValue(label);
			}
		} else {
			let valueArray = [...optionValues.value];
			if (valueArray.includes(label)) {
				optionHelper.setValue(valueArray.filter((value) => value !== label));
			} else {
				valueArray.push(label);
				optionHelper.setValue(valueArray);
			}
		}
	}
	return (
		<div className="mahlzeit">
			<div
				className="field-group"
				onClick={() => checkHelper.setValue(!checkValues.value)}
			>
				<Field
					type="checkbox"
					id={data.titel}
					name={`Bestellung[${alternativeIndex}].checked`}
				/>
				<label htmlFor={data.titel}>
					{data.titel}
					{data.preis && (
						<span className="price">
							{parseFloat(data.preis).toLocaleString('de-DE', {
								style: 'currency',
								currency: 'EUR',
							})}
						</span>
					)}
				</label>
				{data.beschreibung && (
					<div className="description">{data.beschreibung} </div>
				)}
			</div>
			{nameValues.value.checked && (
				<div>
					<div className="description">{data.headline_zusatzoptionen}</div>
					{data.zusatzoptionen &&
						data.zusatzoptionen.map((option, idx) => {
							return (
								<div
									onClick={() => {
										addRemove(option.titel);
									}}
									key={`zusatzoption-${idx}`}
									className="field-group sub"
								>
									<Field
										type={data.zusatzoption_single ? 'radio' : 'checkbox'}
										id={data.titel + '_' + option.titel}
										value={option.titel}
										name={`Bestellung[${alternativeIndex}].zusatzoptionen`}
									/>
									<label htmlFor={data.titel + '_' + option.titel}>
										{option.titel}
									</label>
								</div>
							);
						})}
				</div>
			)}
		</div>
	);
}
