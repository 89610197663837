import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useData } from "../../../util/dataProvider";
import Meldungen from "./Meldungen";
import Logo from "../../../assets/eezy.svg";

export default function Bereich() {
  const { slug } = useParams();
  const { data, setTitle } = useData();

  const [nineeuroNews, setNineEuroNews] = useState();

  useEffect(() => {
    const scrollDiv = document.getElementById("Content");
    if (scrollDiv && localStorage.getItem("posiY")) {
      scrollDiv.scrollTo(0, localStorage.getItem("posiY"));
      localStorage.setItem("posiY", 0);
    }
    setTitle("Deutschlandticket");
  }, []);

  useEffect(() => {
    generatenineeuroNews();
  }, [data]);

  function generatenineeuroNews() {
    let posts = [];
    for (let i = 0; i < data.length; i++) {
      data[i].posts.map((post) => {
        if (post.nine_topic) {
          post.extraSlug = data[i].slug;
          posts.push(post);
        }
      });
    }

    if (posts.length > 0) {
      setNineEuroNews(posts);
    } else {
      setNineEuroNews(false);
    }
  }

  return (
    <div id="Bereich">
      <div
        className="header-bild"
        style={{
          backgroundImage: `url(https://sws-cms.de/wp-content/uploads/2023/03/Bus_gelb_D-Ticket.png)`,
        }}
      ></div>
      <div className="intro-container">
        <div
          className="intro"
          style={{
            backgroundColor: "#FFF",
            border: "2px solid #125185",
            backgroundPosition: "center",
            backgroundSize: "80%  ",
            backgroundRepeat: "no-repeat",
            color: "#125185",
            fontSize: "16px",
            fontWeight: "bold",
            display: "flex",
            justifyContent: "center",
          }}
        >
          D-TICKET
        </div>
        <div className="header-title">Deutschlandticket</div>
      </div>
      <Meldungen farbe={"#fdcc2b"} data={nineeuroNews} />
    </div>
  );
}
