import React, { useState, useEffect } from "react";
import Back from "../../assets/back.png";
import { useHistory, useLocation } from "react-router-dom";
import { useData } from "../../util/dataProvider";
import Footer from "./Footer";
import axios from "axios";
import config from "../../util/config";
import spinner from "../../assets/spinner.svg";
export default function Layout({ children }) {
  const history = useHistory();
  const location = useLocation();
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const { title, setSearchResult, setShowNews } = useData();
  const backbutton = () => {
    switch (location.pathname) {
      case "/":
      case "/bereich":
      case "/tools":
      case "/kontakte":
      case "/downloads":
      case "/menu":
        return false;
        break;
      default:
        return true;
    }
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    let token = localStorage.getItem("token");
    if (searchValue.length > 2) {
      setLoading(true);
      axios
        .post(`${config.url}/search`, {
          cancelToken: source.token,
          token: token,
          term: searchValue,
        })
        .then((res) => {
          setSearchResult(res.data);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } else {
      setSearchResult(null);
    }
  }, [searchValue]);

  return (
    <div id="Layout">
      <div id="Background"></div>
      <div
        id="Header"
        style={{
          justifyContent:
            location.pathname === "/search" ? "start" : "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: 20,
          }}
        >
          {backbutton() ? (
            <img
              className="back"
              onClick={() => history.goBack()}
              src={Back}
              alt={"back"}
            />
          ) : (
            ""
          )}
          <div dangerouslySetInnerHTML={{ __html: title }}></div>
        </div>
        {location.pathname === "/search" ? (
          <div className="search-input-container">
            <input
              placeholder="Suchbegriff"
              className="search-input"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            {loading && <img className="spinner" src={spinner} />}
          </div>
        ) : (
          <div
            className="search-icon"
            onClick={() => {
              history.replace("/search");
            }}
          ></div>
        )}
      </div>
      <div id="Content">
        {children}
        <Footer />
      </div>
    </div>
  );
}
