import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Axios from 'axios';
import config from '../../../util/config';
import PreviewMeldung from '../Meldung/PreviewMeldung';
import Error from '../../common/Error';
import Loader from '../../common/Loader';

export default function Preview() {
	const { id, nonce, bereich } = useParams();
	const [data, setData] = useState();
	const [loading, setLoading] = useState(true);
	const [authorized, setAuthorized] = useState(false);
	const [error, setError] = useState();
	const fetchPreview = () => {
		setLoading(true);
		setError('');
		Axios.post(config.url + '/preview', {
			id: id,
			nonce: nonce,
			bereich: bereich,
		})
			.then((response) => {
				if (response.data) {
					setData(response.data);
					setAuthorized(true);
					setLoading(false);
				}
			})
			.catch((error) => {
				setAuthorized(false);
				setError(error.response && error.response.data.message);
				setLoading(false);
			});
	};

	useEffect(() => {
		fetchPreview();
	}, [authorized]);

	if (!data || loading) return <Loader />;
	if (error) return <Error error={error} />;
	return <PreviewMeldung data={data} />;
}
