import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Bereiche from './Bereiche'
import Aktuell from './Aktuell'
import Welcome from './Welcome'
import Footer from './Footer'
import Tools from './Tools'
import News from './News'

import { useData } from '../../../util/dataProvider'

export default function Dashboard() {
  const history = useHistory()
  const { data, lunchpaket, setTitle, showNews, setShowNews } = useData()
  useEffect(() => {
    setTitle('Bereiche')
    const scrollDiv = document.getElementById('Content')
    if (scrollDiv) {
      scrollDiv.scrollTo(0, 0)
    }
  }, [])

  const [meldungen, setMeldungen] = useState(showNews && showNews.length)

  return (
    <div id="Dashboard">
      <Bereiche data={data} />
    </div>
  )
}
