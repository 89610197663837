import React from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { FormattedDate } from '../../common/'
import { useData } from '../../../util/dataProvider'
export default function Meldung(props) {
  const {
    id,
    title,
    date,
    teaser_text,
    vorschaubild,
    author,
    extraSlug,
    bereich_title,
    bereich_icon,
    bereich_farbe,
  } = props.data
  const { farbe } = props
  const { slug } = useParams()
  const history = useHistory()
  const { checkViewedPosts } = useData()

  return (
    <div
      className="meldung"
      onClick={() => {
        localStorage.setItem(
          'posiY',
          document.getElementById('Content').scrollTop,
        )
        history.push('/bereich/' + `${slug ? slug : extraSlug}` + '/' + id)
      }}
    >
      <div className="meldung-header">
        <div className="meldung-header-left">
          <div
            className="meldung-icon"
            style={{
              backgroundImage: `url(${bereich_icon})`,
              backgroundColor: bereich_farbe,
            }}
          ></div>
          <div className="meldung-bereich">
            <div
              style={{ fontSize: '16px' }}
              dangerouslySetInnerHTML={{
                __html: bereich_title,
              }}
            ></div>

            <div>
              <FormattedDate date={date} />
            </div>
          </div>
        </div>
        <div className="meldung-header-right">
          <div
            className={`meldung-viewed ${checkViewedPosts(id) ? '' : 'viewed'}`}
          ></div>
        </div>
      </div>
      {vorschaubild && (
        <div
          className="bild"
          style={{
            backgroundImage: 'url(' + vorschaubild + ')',
          }}
        ></div>
      )}
      {!vorschaubild && props.data.nine_topic && (
        <div
          className="bild"
          style={{
            height: '100px',
            width: '100%',
          }}
        ></div>
      )}
      <div className="content">
        <h2>{title}</h2>

        <div
          className="teaser"
          dangerouslySetInnerHTML={{ __html: teaser_text }}
        ></div>
      </div>
    </div>
  )
}
