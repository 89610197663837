import React, { useRef, useEffect, useState, Suspense } from 'react'
import { Link, useParams, useHistory } from 'react-router-dom'

import axios from 'axios'
import Finish from './Finish'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import Loader from '../../common/Loader'
import 'react-datepicker/dist/react-datepicker.css'
import { motion } from 'framer-motion'
import de from 'date-fns/locale/de'
import Resizer from 'react-image-file-resizer'
import Upload from './upload'

registerLocale('de', de)
setDefaultLocale('de')
export default function SingleFormular() {
  const history = useHistory()

  const [date, setDate] = useState(false)
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [formular, setFormular] = useState([])
  const [valueArray, setValueArray] = useState([])
  const [sending, setSending] = useState(false)
  const [finish, setFinish] = useState(false)
  const [file, setFile] = useState([])
  const [disabled, setDisabled] = useState(true)

  const [startDate, setStartDate] = useState(new Date())

  useEffect(() => {
    let token = localStorage.getItem('token')
    axios
      .get(
        'https://www.sws-cms.de/wp-json/sws-cms/v1' +
          '/formulare/' +
          id +
          '/?token=' +
          token,
      )
      .then((res) => {
        setFormular(res.data)
        res.data.fields.forEach((field, idx) => {
          if (field.acf_fc_layout === 'date') {
            updateValueArray(getDate(new Date()), idx)
          }
        })
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])
  console.log(formular)
  useEffect(() => {
    if (!loading) {
      setDisabled(checkPflicht())
    }
  }, [valueArray])

  const styles = {
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },

    title: {
      fontFamily: '"Lato", sans-serif',
      color: 'white',
      fontSize: 20,
    },
    label: {
      fontFamily: '"Lato", sans-serif',
      color: 'white',
      fontSize: 14,

      marginTop: 12,
      fontWeight: 'bold',
    },
    sublabel: {
      fontFamily: '"Lato", sans-serif',
      color: 'white',
      fontSize: 14,
      opacity: 0.7,
      marginBottom: 6,
    },
    textinput: {
      height: 32,
      borderRadius: 3,
      padding: 6,
      backgroundColor: 'white',
      justifyContent: 'center',

      width: '100%',
    },
    textarea: {
      fontFamily: '"Lato", sans-serif',

      height: 96,
      borderRadius: 3,
      padding: 6,
      backgroundColor: 'white',
      width: '100%',
    },
    formular_container: {
      width: '85%',
    },
    btn: {
      display: 'flex',
      marginTop: 20,
      justifyContent: 'center',
      alignItems: 'center',
      padding: 12,
      borderRadius: 3,
      marginBottom: 64,
      backgroundColor: '#fdcc2b',
      width: '100%',
      appearance: 'none',
      border: '0px',
    },
    btn_text: {
      fontFamily: '"Lato", sans-serif',
      color: 'white',
    },
    sending: {
      position: 'absolute',
      height: '100%',
      width: '100%',
      top: 0,
      left: 0,
      backgroundColor: 'rgba(255,255,255,0.5)',
    },
    sending_content: {
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      width: '100%',
      backgroundColor: 'rgba(0,0,0,0.5)',
    },
    radio_group: {
      flexDirection: 'row',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginTop: '8px',
    },
    radio: {
      height: '16px',
      width: '16px',
      marginRight: '4px',
    },
    radio_text: {
      fontFamily: '"Lato", sans-serif',
      color: 'white',
      fontSize: 14,
      fontWeight: 'bold',
    },
  }

  function checkPflicht() {
    let disabled = false
    formular.fields.forEach((field, idx) => {
      if (field.required) {
        if (valueArray[idx]) {
          if (valueArray[idx].value) {
          } else {
            disabled = true
            return disabled
          }
        } else {
          disabled = true
          return disabled
        }
      }
    })
    return disabled
  }
  const submitForm = () => {
    setSending(true)
    let token = localStorage.getItem('token')
    for (let i = 0; i < formular.fields.length; i++) {
      if (typeof valueArray[i] == 'undefined' || !valueArray[i]) {
        valueArray[i] = { value: null }
      }
    }
    let index = formular.fields.findIndex((ele) => ele.acf_fc_layout === 'file')
    if (index >= 0 && file) {
      let promise = []
      for (let j = 0; j < file.length; j++) {
        if (file[j]) {
          promise.push(
            axios
              .post('https://www.sws-cms.de/wp-json/sws-cms/v1' + '/upload', {
                token: token,
                file: file[j],
              })
              .then((res_id) => {
                valueArray[j] = { value: res_id.data }
              }),
          )
        }
      }
      Promise.all(promise).then(() => {
        axios
          .post(
            'https://www.sws-cms.de/wp-json/sws-cms/v1' + '/formulare/' + id,
            {
              token: token,
              formdata: valueArray,
            },
          )
          .then((res) => {
            setSending(false)
            setFinish(true)
          })
          .catch((err) => {
            console.log(err)
            setSending(false)
          })
      })
    } else {
      axios
        .post(
          'https://www.sws-cms.de/wp-json/sws-cms/v1' + '/formulare/' + id,
          {
            token: token,
            formdata: valueArray,
          },
        )
        .then((res) => {
          setSending(false)
          setFinish(true)
        })
        .catch((err) => {
          console.log(err)
          setSending(false)
        })
    }
  }

  const updateValueArray = (value, id) => {
    console.log(value)
    let oldValueArray = [...valueArray]
    oldValueArray[id] = { value: value }
    setValueArray([...oldValueArray])
  }

  const checkboxValueArray = (item, id) => {
    let array = valueArray[id]?.value

    if (array) {
      let index = array.findIndex((ele) => ele == item)
      if (index >= 0) {
        array.splice(index, 1)
      } else {
        array.push(item)
      }
      let oldValueArray = [...valueArray]
      oldValueArray[id] = { value: array }
      setValueArray([...oldValueArray])
    } else {
      array = [...valueArray]
      array[id] = { value: [] }

      array[id].value.push(item)
      setValueArray([...array])
    }
  }

  function getDate(d) {
    if (!d) var d = new Date()
    let month = '' + (d.getMonth() + 1)
    let day = '' + d.getDate()
    let year = d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day
    return [day, month, year].join('-')
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        500,
        500,
        'JPEG',
        100,
        0,
        (uri) => {
          resolve(uri)
        },
        'base64',
      )
    })

  const fileDataURL = (file) =>
    new Promise((resolve, reject) => {
      let fr = new FileReader()

      fr.onload = () => resolve(fr.result)
      fr.onerror = reject
      fr.readAsDataURL(file)
    })

  function showResult(file) {
    resizeFile(file)
      .then((data) => {
        setFile(data)
        console.log(data)
      })
      .catch((err) => console.log(err))
  }

  const onImageChange = (event, id) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0]
      showResult(img)
    }
  }

  const generateFields = (type, id, field) => {
    switch (type) {
      case 'text':
        return (
          <input
            onChange={(e) => updateValueArray(e.target.value, id)}
            style={styles.textinput}
          />
        )
        break
      case 'date':
        return (
          <DatePicker
            style={styles.textinput}
            selected={startDate}
            onChange={(date) => {
              setStartDate(date)
              updateValueArray(getDate(date), id)
            }}
            locale="pt-BR"
            dateFormat="P"
          />
        )
        break
      case 'textarea':
        return (
          <textarea
            style={styles.textarea}
            onChange={(e) => updateValueArray(e.target.value, id)}
          />
        )
        break
      case 'select':
        return (
          <select
            value={valueArray[id] && valueArray[id].value}
            onChange={(e) => updateValueArray(e.target.value, id)}
          >
            <option value="">Auswählen ...</option>
            {field.auswahlmoglichkeiten.map((item, idx) => {
              return (
                <option key={`item-${idx}`} value={`${item}`}>
                  {item}
                </option>
              )
            })}
          </select>
        )
        break
      case 'radio':
        return (
          <div onChange={(e) => updateValueArray(e.target.value, id)}>
            {field.auswahlmoglichkeiten.map((item) => (
              <div style={styles.radio_group}>
                <input
                  style={styles.radio}
                  type="radio"
                  checked={valueArray[id]?.value == item}
                  value={item}
                  name={item + id}
                />
                <div
                  style={styles.radio_text}
                  onClick={() => updateValueArray(item, id)}
                >
                  {item}
                </div>
              </div>
            ))}
          </div>
        )
        break
      case 'checkbox':
        return (
          <>
            {field.auswahlmoglichkeiten &&
              field.auswahlmoglichkeiten.map((item, idx) => (
                <div key={`item-${idx}`} style={styles.radio_group}>
                  <input
                    style={styles.radio}
                    type="checkbox"
                    checked={
                      valueArray[id]?.value.findIndex((ele) => ele == item) >= 0
                    }
                    value={item}
                    onChange={(e) => checkboxValueArray(e.target.value, id)}
                  />
                  <div
                    onClick={() => checkboxValueArray(item, id)}
                    style={styles.radio_text}
                  >
                    {item}
                  </div>
                </div>
              ))}
          </>
        )
        break
      case 'file':
        return <Upload file={file} setFile={setFile} id={id} />
        break
      default:
        return <div></div>
    }
  }

  const item = {
    hidden: { x: '-150%' },
    show: { x: 1 },
  }

  if (finish) {
    return <Finish />
  }
  if (sending || loading) {
    return <Loader />
  } else {
    return (
      <motion.div
        transition={{ ease: 'easeOut' }}
        variants={item}
        initial="hidden"
        animate="show"
        id="form-builder-container"
        style={styles.container}
      >
        <div style={styles.title}>{formular.title}</div>
        <div style={styles.formular_container}>
          {formular &&
            formular.fields.map((field, idx) => {
              return (
                <div key={`field-${idx}`}>
                  <div style={styles.label}>
                    {field.label} {field.required ? '*' : ''}
                  </div>
                  {field.descirption ? (
                    <div style={styles.sublabel}>{field.descirption}</div>
                  ) : null}
                  {generateFields(field.acf_fc_layout, idx, field)}
                </div>
              )
            })}
          <div
            style={{
              color: 'white',
              fontSize: 14,
              opacity: 0.7,
              marginBottom: 6,
              marginTop: 12,
            }}
          >
            * Pflichtfeld
          </div>
          <button onClick={submitForm} disabled={disabled} style={styles.btn}>
            <div style={styles.btn_text}>Meldung einreichen</div>
          </button>
        </div>
      </motion.div>
    )
  }
}
