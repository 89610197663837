import React, { useEffect } from 'react'
import './App.scss'
import { Route, Switch, ScrollRestoration, useLocation } from 'react-router-dom'
import { useData } from './util/dataProvider'
import { Loader, Layout, ScrollTop } from './components/common/'
import {
  Dashboard,
  Bereich,
  Meldung,
  Login,
  Settings,
  Preview,
  RedirectLogin,
  Password,
  Privacy,
  Kontakte,
  Downloads,
  Faq,
  News,
  Tools,
  Menu,
  Search,
  Nineeuro,
  NineeuroBereich,
} from './components/pages'
import { Lunchpaket, FormBuilder, SingleFormular } from './components/Tools'
import ErrorBoundary from './errorboundary'

function App() {
  const { loading, data, authorized, hasReadPrivacy, lunchpaket } = useData()
  const date = new Date()

  const location = useLocation()

  return (
    <ErrorBoundary>
      <div className="App">
        {loading && <Loader />}
        {authorized && data.length ? (
          <Layout>
            {hasReadPrivacy ? null : <Privacy />}
            <Switch>
              <Route path="/redirect/:token" component={RedirectLogin} />
              {data && (
                <Route
                  path="/preview/:nonce/:bereich/:id"
                  component={Preview}
                />
              )}
              }
              {data && (
                <Route
                  path="/nineeurobereich"
                  component={NineeuroBereich}
                ></Route>
              )}
              {data && (
                <Route path="/bereich/:slug/:id" component={Meldung}></Route>
              )}
              {data && <Route path="/kontakte" component={Kontakte}></Route>}
              {data && <Route path="/downloads" component={Downloads}></Route>}
              {data && <Route path="/nineeuro" component={Nineeuro}></Route>}
              {data && (
                <Route path="/bereich/:slug" component={Bereich}></Route>
              )}
              {data && <Route path="/tools" component={Tools}></Route>}
              {lunchpaket && (
                <Route path="/tool/lunchpaket" component={Lunchpaket}></Route>
              )}
              <Route
                path="/tool/formbuilder/:id"
                component={SingleFormular}
              ></Route>
              <Route path="/tool/formbuilder" component={FormBuilder}></Route>
              {data && (
                <Route path="/einstellungen" component={Settings}></Route>
              )}
              {data && <Route path="/faq" component={Faq}></Route>}
              {data && <Route path="/menu" component={Menu}></Route>}
              <Route path="/password" component={Password}></Route>
              <Route path="/search" component={Search}></Route>
              {data && <Route path="/bereich" component={Dashboard}></Route>}
              {data && <Route path="/" component={News}></Route>}
            </Switch>
          </Layout>
        ) : (
          <Switch>
            <Route path="/redirect/:token" component={RedirectLogin} />
            <Route path="/" component={Login} />
          </Switch>
        )}
      </div>
    </ErrorBoundary>
  )
}

export default App
