import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { useData } from '../../../util/dataProvider'
import Meldungen from '../Bereich/Meldungen'
import Meldung from '../Bereich/Meldung'
import { motion } from 'framer-motion'

export default function News({ data, nine }) {
  const history = useHistory()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const { showNews, setShowNews, setTitle, checkViewedPosts } = useData()

  useEffect(() => {
    setTitle('<span class="yellow">SWS</span> mobi')
  }, [])
  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.2,
      },
    },
  }

  const item = {
    hidden: { x: '-150%' },
    show: { x: 0 },
  }
  if (nine) {
    return (
      <div className="News" id="Bereich">
        <motion.div
          variants={container}
          initial="hidden"
          animate="show"
          className="meldungen"
        >
          {data &&
            data.map((meldung) => {
              return (
                <motion.div
                  transition={{ ease: 'easeOut' }}
                  key={meldung.id}
                  variants={item}
                >
                  <Meldung farbe={meldung.bereich_farbe} data={meldung} />
                </motion.div>
              )
            })}
        </motion.div>
      </div>
    )
  }

  return (
    <div className="News" id="Bereich">
      <motion.div
        variants={container}
        initial="hidden"
        animate="show"
        className="meldungen"
      >
        {showNews &&
          showNews.map((meldung) => {
            return (
              <motion.div
                transition={{ ease: 'easeOut' }}
                key={meldung.id}
                variants={item}
              >
                <Meldung farbe={meldung.bereich_farbe} data={meldung} />
              </motion.div>
            )
          })}
      </motion.div>
    </div>
  )
}
