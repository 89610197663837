import React, { useRef, useEffect, useState } from "react";
import { useData } from "../../../util/dataProvider";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import config from "../../../util/config";
import Loader from "../../common/Loader";
import "./formbuilder.scss";
export default function Formular() {
  const history = useHistory();
  const { dienstPlan, setTitle, appID, retrieveData } = useData();
  const [loading, setLoading] = useState(true);
  const [formular, setFormular] = useState([]);

  useEffect(() => {
    let token = localStorage.getItem("token");
    setTitle("Meldungen");
    axios
      .get(
        "https://www.sws-cms.de/wp-json/sws-cms/v1" +
          "/formulare/?token=" +
          token
      )
      .then((res) => {
        setFormular(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const styles = {
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    meldung_container: {
      borderRadius: 5,
      width: "90%",
      margin: "24px auto",
      borderRadius: "3px",
    },
    meldung: {
      display: "flex",
      backgroundColor: "white",
      padding: 16,
      borderBottomWidth: 1,
      borderBottomColor: "#ccc",
      width: "100%",
      flexDirection: "row",
      alignItems: "center",
    },
    title: {
      color: "white",
      fontSize: 20,
      textDecoration: "none",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.title}>Was möchtest du melden?</div>
      {loading ? (
        <Loader />
      ) : formular.length == 0 ? (
        <div
          style={{ marginTop: 32, color: "white", width: "100%", padding: 16 }}
        >
          Derzeit sind keine Formulare aktiv.
        </div>
      ) : (
        <div style={styles.meldung_container}>
          {formular &&
            formular.map((meldung, idx) => {
              return (
                <Link
                  key={`meldung-${idx}`}
                  to={`/tool/formbuilder/${meldung.id}`}
                  style={{ textDecoration: "none" }}
                >
                  <div style={styles.meldung}>
                    <div
                      className="meldung-icon"
                      style={{
                        backgroundImage: `url(${meldung.icon})`,
                      }}
                    ></div>
                    <div
                      style={{
                        color: "#5f717e",
                      }}
                    >
                      {meldung.title}
                    </div>
                  </div>
                </Link>
              );
            })}
        </div>
      )}
    </div>
  );
}
