import React, { useEffect, useRef } from "react";
import { useData } from "../../../util/dataProvider";
import "./search.scss";
import { useHistory } from "react-router-dom";
export default function Search() {
    const { data, setTitle, searchResult } = useData();
    const history = useHistory();
    useEffect(() => {
        setTitle(" ");
    }, []);

    const dateTransform = (date) => {
        const unixTimestamp = date;
        const milliseconds = unixTimestamp * 1000; // 1575909015000
        const dateObject = new Date(milliseconds);
        return `${dateObject.getDay()}.${
            dateObject.getMonth() + 1
        }.${dateObject.getFullYear()}`;
    };
    return (
        <div className='search-container'>
            {searchResult && searchResult.length > 0 ? (
                <>
                    {searchResult.map((post, idx) => {
                        return (
                            <div
                                key={`searchpost-${idx}`}
                                onClick={() => {
                                    history.push(
                                        `/bereich/${post.bereich_slug}/${post.id}`
                                    );
                                }}>
                                <div className='search-item'>
                                    <div
                                        className='search-item-icon'
                                        style={{
                                            backgroundImage: `url(${post.bereich_icon})`,
                                            backgroundColor: post.bereich_farbe,
                                        }}></div>
                                    <div style={{ justifyContent: "center" }}>
                                        <div className='text'>{post.title}</div>
                                        <div className='text sub'>
                                            Artikel vom{" "}
                                            {dateTransform(post.date)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </>
            ) : searchResult && searchResult.length === 0 ? (
                <div>
                    <div className='text'>
                        Es wurden keine Inhalte mit Ihrem Suchbegriff gefunden.
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </div>
    );
}
