import React, { useEffect, Fragment } from "react";
import { motion } from "framer-motion";
import { useData } from "../../../util/dataProvider";

export default function Header(props) {
    const { title, farbe, icon, kurzname } = props.data;
    const { setTitle } = useData();
    useEffect(() => {
        setTitle(title);
    }, []);
    const item = {
        delay: 0.5,
        hidden: { y: -100 },
        show: { y: 0 },
    };

    return (
        <Fragment>
            <div
                className='header-bild'
                style={{
                    backgroundImage: props.data.headerbild
                        ? `url(${props.data.headerbild})`
                        : "white",
                }}></div>
            <div className='intro-container'>
                <div
                    className='intro'
                    style={{
                        backgroundImage: `url(${icon})`,
                        backgroundColor: farbe,
                    }}></div>
                <div className='header-title'>{kurzname}</div>
            </div>
        </Fragment>
    );
}
