import React from "react";
import { FormattedDate } from "../../common";
import Text from "./Text";
import Video from "./Video";
import Bild from "./Bild";
import PDF from "./PDF";
import user from "../../../assets/sws-user.svg";

import { motion } from "framer-motion";

export default function PreviewMeldung({ data }) {
    const meldung = data.posts[0];
    const { date, additionalContent, content } = meldung;

    const item = {
        hidden: { x: -100 },
        show: { x: 1 },
    };
    const bild = meldung.vorschaubild;
    const author = meldung.author;

    return (
        <motion.div
            transition={{ ease: "easeOut" }}
            variants={item}
            initial='hidden'
            animate='show'
            id='Meldung'>
            <div className='content'>
                <div
                    className='border'
                    style={{ backgroundColor: meldung.bereich_farbe }}></div>
                {bild && (
                    <div
                        className='bild'
                        style={{
                            backgroundColor: meldung.bereich_farbe,
                            backgroundImage: "url(" + bild + ")",
                        }}></div>
                )}
                <div className='inner'>
                    <h1>{meldung.title}</h1>
                    <FormattedDate date={date} />{" "}
                    {author && (
                        <div className='meldung-box'>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}>
                                <div
                                    className='img'
                                    style={{
                                        backgroundImage: author.profilepic
                                            ? `url(${author.profilepic})`
                                            : `url(${user})`,
                                    }}></div>
                                <div className='info'>
                                    <span id='ansprechpartner'>
                                        Ihr Ansprechpartner:
                                    </span>
                                    <span className='name'>{author.name}</span>
                                </div>
                            </div>
                            <div className='partner-email'>
                                <a
                                    href={`tel:${
                                        author.phone &&
                                        author.phone
                                            .replace(/\s*/g, "")
                                            .replace(/\([^\)]*\)/g, "")
                                            .replace(/\/*/g, "")
                                            .replace(/\-*/g, "")
                                    }`}>
                                    <div className='icon-phone'></div>
                                </a>
                                <a href={`mailto:${author.email}`}>
                                    <div className='icon-email'></div>
                                </a>
                            </div>
                        </div>
                    )}
                    <div className='meldung-content'>
                        {content &&
                            content.map((zeile) => {
                                return zeile.map((spalte, idx) => {
                                    if (spalte.typ === "text")
                                        return (
                                            <Text
                                                key={idx}
                                                text={spalte.text}
                                            />
                                        );
                                    if (spalte.typ === "video")
                                        return (
                                            <Video
                                                key={idx}
                                                video={spalte.video}
                                            />
                                        );
                                    if (spalte.typ === "bild")
                                        return (
                                            <Bild
                                                key={idx}
                                                bild={spalte.bild}
                                            />
                                        );
                                    if (spalte.typ === "pdf")
                                        return (
                                            <PDF key={idx} pdf={spalte.pdf} />
                                        );
                                    return null;
                                });
                            })}
                        {additionalContent && <Text text={additionalContent} />}
                    </div>
                </div>
            </div>
        </motion.div>
    );
}
